import React, { useEffect } from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import Work from '../Work/Work';
import Article from './Article';
import withData from '../../shared/withData';

const WorkWithData = withData(Work);

const renderArticle = (url) => (props) => (
  <Article key={props.id} url={url} {...props} />
);

const Works = ({ data }) => {
  const { url, path, isExact } = useRouteMatch();

  useEffect(() => {
    if (isExact) {
      document.title = 'V2A | Tervek'
    }
  }, [isExact]);

  return (
    <Switch>
      <Route exact path={`${path}`}>
        <main className="works">
          <h1>Tervek</h1>
          <div className="grid">
            {data.map(renderArticle(url))}
          </div>
          <div className="photographer">
            Fotók: <a href="https://www.flickr.com/photos/apetrovics/" target="_blank">Petrovics Zsombor</a>
          </div>
        </main>
      </Route>
      <Route path={`${path}/:id`} strict>
        <WorkWithData />
      </Route>
    </Switch>
  );
};

export default Works;
