import React, { useEffect } from 'react';

const About = () => {  
  useEffect(() => {
    document.title = 'V2A | Infó'
  }, []);
  
  return (
   <main className="about">
      <h1>Infó</h1>
      <section>
        <div>
          <h2>V2A GRUP Kft.</h2>
          <p>3016 Boldog, József A. u. 26.</p>
          <p>+36 20-362-90-19</p>
          <p>v2a@v2a.hu</p>
        </div>
        <div>
          <h4>Alapító:</h4>
          <h2>Varga Szabolcs</h2>
          <p>építésztervező szakmérnök</p>
          <p>építészmérnök, mérnök-tanár</p>
        </div>
      </section>
   </main>
  );
};

export default About;
