import React from 'react';
import { NavLink } from 'react-router-dom';
import { ROUTES } from '../../shared/routes';

const Header = () => {
  return (
    <header>
      <div className="logo">
        <NavLink to={`${ROUTES.WORKS}`} activeClassName="active">
          <img src="/img/logo.png" />
        </NavLink>
      </div>
      <nav>
        <ul>
          <li>
            <NavLink to={`${ROUTES.WORKS}`} activeClassName="active">Tervek</NavLink>
          </li>
          <li>
            <NavLink exact to={ROUTES.ABOUT} activeClassName="active">Infó</NavLink>
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;
