import React from 'react';
import { Link } from 'react-router-dom';
import Img from '../Image/Image';

const getImage = (images, thumbnail) => (
  (thumbnail && images.find(({ src }) => src === thumbnail)) ||
  images[0]
);

const Article = ({ id, name, images, venue, url, thumbnail }) => {
  const img = getImage(images, thumbnail);
  return (
    <Link to={`${url}/${id}`} key={id}>
      <article>
        <figure>
          <Img src={`/img/works/${id}/${img.src}`} orientation={img.orientation} />
          <figcaption>
            <h1>{name}</h1>
            <p>{venue}</p>
          </figcaption>
        </figure>
      </article>
    </Link>
  );
};

export default Article;
