import React, { useEffect, useState } from 'react';
import { useWindowSize } from '../../shared/useWindowSize';

const getDivider = (windowWidth) => {
  let divider = 3.6;
  
  if (windowWidth < 1600) {
    divider = 3.8;
  }
  
  if (windowWidth < 1420) {
    divider = 2.5;
  }
  
  if (windowWidth < 1280) {
    divider = 1.5;
  }
  
  if (windowWidth < 960) {
    divider = 1.2;
  }

  return divider;
};

const getDimensions = (windowWidth, orientation, featured) => {
  const ratio = 2 / 3;
  const divider = getDivider(windowWidth);

  const short = windowWidth / divider;
  const long = short * ratio;
  
  let width = Math.floor(orientation === 'landscape' ? short : long);
  let height = Math.floor(orientation === 'portrait' ? short : long);

  if (featured && windowWidth > 1280) {
    width = width * 1.3;
    height = height * 1.3;
  }
  
  return { width, height };
};

const Img = ({ src, orientation, className = '' }) => {
  const { width } = useWindowSize();

  const [loading, setLoading] = useState(true);
  const [dimension, setDimension] = useState(
    getDimensions(width, orientation, className === 'featured')
  );

  let style = {
    ...dimension,
  };

  useEffect(() => {
    const img = new Image();
    img.src = src;
  
    img.onload = () => {
      setLoading(false);
    }
  }, []);

  const setImageSizes = () => {
    setDimension(getDimensions(width, orientation, className === 'featured'));
  };

  useEffect(() => {
    window.addEventListener('load', setImageSizes);

    return () => {
      window.removeEventListener('load', setImageSizes);
    };
  }, []);

  const ref = document.querySelector(`[data-src="${src}"]`);
  if (ref) {
    ref.style.width = `${style.width}px`;
    ref.style.height = `${style.height}px`;
  }
  
  if (loading) {
    return (
      <div style={style} className={`image loading ${orientation} ${className}`}>
        <div className="loader" />
      </div>
    );
  } else {
    style = {
      ...style,
      backgroundImage: `url(${src})`
    };
    return (
      <div
        data-src={src}
        className={`image ${orientation} ${className}`}
        style={style}
      />
    );
  }
};

export default Img;
