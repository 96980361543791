import React from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { ROUTES } from './shared/routes';
import withData from './shared/withData';
import ScrollToTop from './components/ScrollToTop/ScrollToTop';
import Header from './components/Header/Header';
import About from './components/About/About';
import Works from './components/Works/Works';

import './App.scss';

const WorksWithData = withData(Works);

const App = () => {
  return (
    <Router>
      <ScrollToTop />
      <div className="App">
        <Header />
        <Switch>
          <Route path={ROUTES.WORKS} strict>
            <WorksWithData />
          </Route>
          <Route exact path={ROUTES.ABOUT} strict>
            <About />
          </Route>
          <Redirect from="*" to={ROUTES.WORKS} />
        </Switch>
      </div>
    </Router>
  );
}

export default App;
