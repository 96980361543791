import React from 'react';
import Lightbox from 'react-image-lightbox';

const Gallery = ({ images, photoIndex, setPhotoIndex, setGalleryOpen }) => (
  <Lightbox
    enableZoom={false}
    mainSrc={images[photoIndex]}
    nextSrc={images[(photoIndex + 1) % images.length]}
    prevSrc={images[(photoIndex + images.length - 1) % images.length]}
    onCloseRequest={() => {
      setGalleryOpen(false);
    }}
    onMovePrevRequest={() => {
      setPhotoIndex((photoIndex + images.length - 1) % images.length);
    }}
    onMoveNextRequest={() => {
      setPhotoIndex((photoIndex + 1) % images.length);
    }}
  />
);

export default Gallery;
