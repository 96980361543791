import React from 'react';
import Img from '../Image/Image';

const Article = ({ id, index, img, setGalleryOpen, setPhotoIndex}) => (
  <article
    onClick={() => {
      setPhotoIndex(index);
      setGalleryOpen(true);
    }}
  >
    <figure>
      <Img src={`/img/works/${id}/${img.src}`} orientation={img.orientation} />
    </figure>
  </article>
);

export default Article;
