import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Article from './Article';
import Gallery from './Gallery';
import Img from '../Image/Image';

const getData = (workId) => ({ id }) => id === workId;

const getGalleryData = (id, images) => images.map(img => `/img/works/${id}/${img.src}`);

const renderImages = (id, setGalleryOpen, setPhotoIndex) => (img, index) => (
  <Article
    key={`${id}-${img.src}`}
    id={id}
    img={img}
    index={index}
    setGalleryOpen={setGalleryOpen}
    setPhotoIndex={setPhotoIndex}
  />
);

const renderDescription = ([projectType, ...info]) => ([
  <h4 key="project-type">{projectType}</h4>,
  ...info.map((item, index) => <p key={`description-${index}`}>{item}</p>)
]);

const Work = ({ data }) => {
  const { id: workId } = useParams();
  const [isGalleryOpen, setGalleryOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);

  const work = data.find(getData(workId));
  const [headlineImage, ...images] = work.images;

  useEffect(() => {
    document.title = `V2A | ${work.name} - ${work.venue}`
  }, []);

  return (
   <main className="work">
      <h1>{work.name}</h1>
      <h3>{work.venue}</h3>
      <div className="grid">
        <div className="headline">
          <article>
            <figure>
              <Img
                src={`/img/works/${work.id}/${headlineImage.src}`}
                orientation={headlineImage.orientation}
                className="featured"
              />
            </figure>
          </article>
          <article className="text">
            {renderDescription(work.description)}
          </article>
        </div>
        {images.map(renderImages(work.id, setGalleryOpen, setPhotoIndex))}
        {isGalleryOpen && (
          <Gallery
            images={getGalleryData(workId, images)}
            photoIndex={photoIndex}
            setPhotoIndex={setPhotoIndex}
            setGalleryOpen={setGalleryOpen}
          />
        )}
      </div>
   </main>
  );
};

export default Work;
